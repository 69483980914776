import React, { useEffect, useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AppSettings } from './../../../config/app-settings.js';
import axiosClient from '../../../config/axios.js';
import Swal from 'sweetalert2';


import { useAuth } from '../../../context/AuthContext';


function Login() {
	const { login } = useAuth();

	const context = useContext(AppSettings);
	const [credentials, saveCredentials] = useState({});
	const [redirect, setRedirect] = useState(false);



	const handleLogin = async e => {
		e.preventDefault();

		try {
			const response = await axiosClient.post('/login', credentials);

			login(response.data.response.token, response.data.response.fullName, response.data.response.userId, response.data.response.username);
			setRedirect(true);
		} catch (err) {
			Swal.fire({
				title: err.response.data.message,
				text: null,
				icon: 'error',
				confirmButtonText: 'Cerrar',
				cancelButtonText: 'Cancelar',
				showCancelButton: false,
			});
		}
	}

	//Saving in state the field values
	const readData = e => {
		saveCredentials({
			...credentials,
			[e.target.name]: e.target.value
		})
	}

	useEffect(() => {
		context.handleSetAppHeaderNone(true);
		context.handleSetAppSidebarNone(true);
		context.handleSetAppContentClass('p-0');

		return function cleanUp() {
			context.handleSetAppHeaderNone(false);
			context.handleSetAppSidebarNone(false);
			context.handleSetAppContentClass('');
		};

		// eslint-disable-next-line
	}, []);



	if (redirect) {
		return <Navigate to='/' />;
	}

	return (
		<div className="login login-v1">
			<div className="login-container">
				<div className="login-header">
					<div className="brand">
						<div className="d-flex align-items-center">
							<span className="logo"></span> <b>Grúas</b>  Varo
						</div>
						<small>Sistema de Administración</small>
					</div>
					<div className="icon">
						<i className="fa fa-lock"></i>
					</div>
				</div>
				<div className="login-body">
					<div className="login-content fs-13px">
						<form onSubmit={handleLogin}>
							<div className="form-floating mb-20px">
								<input type="text" className="form-control fs-13px h-45px" id="emailAddress" name="username" placeholder="Usuario" onChange={readData} />
								<label htmlFor="emailAddress" className="d-flex align-items-center py-0">Usuario</label>
							</div>
							<div className="form-floating mb-20px">
								<input type="password" className="form-control fs-13px h-45px" id="password" name="password" placeholder="Contraseña" onChange={readData} />
								<label htmlFor="password" className="d-flex align-items-center py-0">Contraseña</label>
							</div>
							<div className="form-check mb-20px">
								<input className="form-check-input" type="checkbox" value="" id="rememberMe" />
								<label className="form-check-label" htmlFor="rememberMe">
									Recordarme
								</label>
							</div>
							<div className="login-buttons">
								<button type="submit" className="btn h-45px btn-theme d-block w-100 btn-lg">Iniciar sesión</button>
							</div>
							<div className="form-floating mt-20px">
								Versión grúas: 1.1.6v
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Login;
import React, { Fragment, useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { Panel, PanelHeader, PanelBody } from '../../../components/panel/panel';
import Dropzone from 'react-dropzone';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Field, ErrorMessage } from 'formik';
import { Tooltip } from 'react-tooltip';
import Highlight from 'react-highlight';

import CurrencyInput from 'react-currency-input-field';
import InputGroup from 'react-bootstrap/InputGroup';

import Swal from 'sweetalert2';

import 'animate.css/animate.min.css';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import axiosClient from '../../../config/axios.js';



import { useAuth } from '../../../context/AuthContext.js';
import OrderFieldsDatosContacto from './orderSections/orderFieldsDatosContacto';

import OrderAmountsVariables from './orderAmounts/orderAmountsVariables';
import OrderAmountsFieldArray from './orderAmounts/orderAmountsFieldArray';
import OrderFieldsInformacionGeneral from './orderSections/orderFieldsInformacionGeneral';
import OrderFieldsInformacionTipoServicio from './orderSections/orderFieldsInformacionTipoServicio';
import OrderFieldsUbicacionAtencion from './orderSections/orderFieldsUbicacionAtencion';
import OrderFieldsDatosBienes from './orderSections/orderFieldsDatosBienes';
import OrderFieldsEvaluacion from './orderSections/orderFieldsEvaluación';
import OrderStatus from './orderStatus/orderStatus';
import OrderFieldsResultAmount from './orderSections/orderFieldsResultAmount';
import OrderFieldsEvidencias from './orderSections/orderFieldsEvidencias';

import PaymentsAndInvoices from './orderPayments/paymentsAndInvoices';


const OrderFields = React.forwardRef(({ id, operadores, editable, validateForm, values, handleSubmit, handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors, opcionesEsCita, fetchCurrentOrderStatusByOrder, reloadOrderDataFromDB, handleCancelOrder, handleEndCancelOrder, handlePrintOrder, currentOrderStatus, setCurrentOrderStatus, isSubmitting, setSubmitting, tablePaymentsContadoRef, tablePaymentsCreditoRef }, ref) => {
    //function OrderFields({ operadores, editable, validateForm, values, handleSubmit, handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors, opcionesEsCita, fetchCurrentOrderStatusByOrder, reloadOrderDataFromDB, handleCancelOrder, handleEndCancelOrder, handlePrintOrder, currentOrderStatus, setCurrentOrderStatus, isSubmitting, setSubmitting, tablePaymentsContadoRef, tablePaymentsCreditoRef }) {

    //const { id } = useParams();
    const { token, userId, fullName, username } = useAuth();

    const [images, setImages] = useState([]);
    const navigate = useNavigate();

    const [tipoClientes, setTipoClientes] = useState([]);
    const [showEvidences, setShowEvidences] = useState(false);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [gruas, setGruas] = useState([]);
    const [aseguradoras, setAseguradoras] = useState([]);
    const [montacargas, setMontacargas] = useState([]);
    const [vehiculosAsistencia, setVehiculosAsistencia] = useState([]);
    const [bases, setBases] = useState([]);

    const [tiposLocalizacionServicio, setTiposLocalizacionServicio] = useState([]);
    const [modalidadesTarifa, setModalidadesTarifa] = useState([
        { value: true, label: 'Tarifa establecida' },
        { value: false, label: 'Realizar cálculo' },
    ]);


    const [tarifasGruaCalculadas, setTarifasGruaCalculadas] = useState([]);
    const [tarifasCasetas, setTarifasCasetas] = useState([]);
    const [tarifasAuxVialCalculadas, setTarifasAuxVialCalculadas] = useState([]);










    const handleDrop = acceptedFiles => {
        const newImages = acceptedFiles.map(file => ({
            original: URL.createObjectURL(file),
            thumbnail: URL.createObjectURL(file),
        }));
        setImages(images.concat(newImages));
    };

    const fetchRate = async (locations_rates, client_type_id, insurance_id, crane_id, rate_code) => {
        try {

            let response = {};
            if (locations_rates && locations_rates.length > 0) {
                response = await axiosClient.get('/rates/getRate', {
                    params: {
                        locations_rates: locations_rates,
                        client_type_id: client_type_id,
                        insurance_id: insurance_id,
                        crane_id: crane_id,
                        rate_code: rate_code
                    },
                });
            }

            return response;
        } catch (error) {
            throw new Error('Error fetching rates:', error);
        }
    };

    function formatNumberWithCommas(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return "";
        }

    }

    const calcularSubtotalAmount = () => {

        let suma = 0;
        //console.log("grua_subtotal", values.grua_subtotal);
        suma += (values.grua_subtotal ? parseFloat(values.grua_subtotal) : 0);
        suma += (values.grua_banderazo ? parseFloat(values.grua_banderazo) : 0);
        suma += (values.grua_casetas ? parseFloat(values.grua_casetas) : 0);
        suma += (values.aux_vial_subtotal ? parseFloat(values.aux_vial_subtotal) : 0);
        suma += (values.aux_vial_banderazo ? parseFloat(values.aux_vial_banderazo) : 0);
        suma += (values.aux_vial_casetas ? parseFloat(values.aux_vial_casetas) : 0);
        suma += (values.montacargas_subtotal ? parseFloat(values.montacargas_subtotal) : 0);
        suma += (values.montacargas_traslado ? parseFloat(values.montacargas_traslado) : 0);
        suma += (values.corresponsalia_subtotal ? parseFloat(values.corresponsalia_subtotal) : 0);
        suma += (values.taxi_subtotal ? parseFloat(values.taxi_subtotal) : 0);
        suma += (values.monto_blindaje ? parseFloat(values.monto_blindaje) : 0);
        suma += (values.monto_carga_volumen ? parseFloat(values.monto_carga_volumen) : 0);
        suma += (values.servicio_muerto_monto_penalizacion ? parseFloat(values.servicio_muerto_monto_penalizacion) : 0);

        if (values && values.montosExtras) {
            values.montosExtras.forEach((montoExtra) => {
                suma += parseFloat(montoExtra.monto_extra_monto || 0);
            });
        }

        let vSubtotal = 0;
        let vIva = 0;
        let vTotal = 0;

        if (values.manejar_precios_netos) {
            if (values.venta_publico) {
                vTotal = suma;
                vIva = 0;
                vSubtotal = vTotal;
            } else {
                vTotal = suma;
                vSubtotal = (Math.round((vTotal / 1.16) * 100) / 100);
                vIva = vTotal - vSubtotal;
            }
        } else {
            if (values.venta_publico) {

                vIva = (Math.round(suma * 0.16 * 100) / 100);
                vSubtotal = suma + vIva;
                vTotal = suma + vIva;
            } else {
                vSubtotal = suma;
                vIva = (Math.round(vSubtotal * 0.16 * 100) / 100);
                vTotal = vSubtotal + vIva;
            }

        }

        vSubtotal = (vSubtotal).toFixed(2);
        vIva = (vIva).toFixed(2);
        vTotal = (vTotal).toFixed(2);

        setFieldValue('subtotal', vSubtotal);
        setFieldValue('iva', vIva);
        setFieldValue('total', vTotal);

        /*if (values.venta_publico) {
            vIva = 0;
            vTotal = subtotal;
        } else {
            vIva = (Math.round(subtotal * 0.16 * 100) / 100);
            vTotal = subtotal + vIva;
        }
 
        vIva = (vIva).toFixed(2);
        vTotal = (vTotal).toFixed(2);
 
        */

    };

    useEffect(() => {

        axiosClient.get('/cranes/all').then(response => {
            const formattedOptions = response.data.data.map(item => {
                const etiqueta = "#" + item.crane_number + ', ' + item.description + ' (' + item.CraneTypes.map(craneType => craneType.crane_type_name).join(', ') + ')';
                return {
                    value: item.id,
                    label: etiqueta,
                    crane_type: item.CraneTypes.map(craneType => craneType.crane_type_name)
                };
            });

            setGruas(formattedOptions);
        }).catch(error => {
            console.error('Error fetching cranes:', error);
        });



        axiosClient.get('/liftTrucks/all').then(response => {

            const formattedOptions = response.data.data.map(liftTruck => ({
                value: liftTruck.id,
                label: liftTruck.lift_truck_number + ' ' + liftTruck.description,
            }));
            setMontacargas(formattedOptions);
        }).catch(error => {
            console.error('Error fetching liftTrucks:', error);
        });

        axiosClient.get('/assistanceVehicles/all').then(response => {

            const formattedOptions = response.data.data.map(assistanceVehicle => ({
                value: assistanceVehicle.id,
                label: assistanceVehicle.VehicleModel.VehicleType.vehicle_type_name + ' ' + assistanceVehicle.VehicleModel.Brand.brand_name + ' ' + assistanceVehicle.VehicleModel.model_name + ' (' + assistanceVehicle.placas + ')',
            }));

            setVehiculosAsistencia(formattedOptions);
        }).catch(error => {
            console.error('Error fetching assistanceVehicles:', error);
        });



        axiosClient.get('/users/allUsers').then(response => {
            const formattedOptions = response.data.data.map(base => ({
                value: base.id,
                label: (base.name + ' ' + base.first_surname + ' ' + (base.second_surname ? base.second_surname : '')).trim(),
            }));
            setBases(formattedOptions);
        }).catch(error => {
            console.error('Error fetching allUsers:', error);
        });


        axiosClient.get('/insurers/all').then(response => {

            const formattedOptions = response.data.data.map(insurance => ({
                value: insurance.id,
                label: insurance.insurance_name,
            }));
            setAseguradoras(formattedOptions);
        }).catch(error => {
            console.error('Error fetching insurers:', error);
        });

        axiosClient.get('/clientTypes/all').then(response => {

            const formattedOptions = response.data.data.map(clientType => ({
                value: clientType.id,
                label: clientType.client_type,
                client_type_code: clientType.client_type_code
            }));
            setTipoClientes(formattedOptions);
        }).catch(error => {
            console.error('Error fetching clientTypes:', error);
        });

        axiosClient.get('/paymentTypes/all').then(response => {

            const formattedOptions = response.data.data.map(paymentType => ({
                value: paymentType.id,
                label: paymentType.payment_type_name,
                code: paymentType.payment_type_code,
                id: paymentType.id
            }));
            setPaymentTypes(formattedOptions);
        }).catch(error => {
            console.error('Error fetching paymentTypes:', error);
        });

        axiosClient.get('/serviceLocationTypes/all').then(response => {

            const formattedOptions = response.data.data.map(serviceLocationType => ({
                value: serviceLocationType.id,
                label: serviceLocationType.service_location_type,
                service_location_code: serviceLocationType.service_location_code,
            }));
            setTiposLocalizacionServicio(formattedOptions);
        }).catch(error => {
            console.error('Error fetching serviceLocationTypes:', error);
        });

    }, []);

    useEffect(() => {
        calcularSubtotalAmount();
        if (touched.montosExtras ||
            touched.manejar_precios_netos ||
            touched.venta_publico ||
            touched.grua_subtotal ||
            touched.grua_banderazo ||
            touched.grua_casetas ||
            touched.aux_vial_subtotal ||
            touched.aux_vial_banderazo ||
            touched.aux_vial_casetas ||
            touched.montacargas_subtotal ||
            touched.montacargas_traslado ||
            touched.corresponsalia_subtotal ||
            touched.taxi_subtotal ||
            touched.monto_blindaje ||
            touched.monto_carga_volumen ||
            touched.servicio_muerto_monto_penalizacion
        ) {
            setFieldTouched("monto_aseguradora_paga", false);
            setFieldTouched("excedente_asegurado", false);

            //quitar comentario pensando setFieldValue("monto_aseguradora_paga", '');
            //quitar comentario pensando setFieldValue("excedente_asegurado", '');
        }

    }, [
        values.montosExtras,
        values.manejar_precios_netos,
        values.venta_publico,
        values.grua_subtotal,
        values.grua_banderazo,
        values.grua_casetas,
        values.aux_vial_subtotal,
        values.aux_vial_banderazo,
        values.aux_vial_casetas,
        values.montacargas_subtotal,
        values.montacargas_traslado,
        values.corresponsalia_subtotal,
        values.taxi_subtotal,
        values.monto_blindaje,
        values.monto_carga_volumen,
        values.servicio_muerto_monto_penalizacion,
    ]);

    useEffect(() => {
        if (touched.monto_aseguradora_paga) {
            if (values.total && values.monto_aseguradora_paga) {
                if (parseFloat(values.monto_aseguradora_paga) > parseFloat(values.total)) {
                    //console.log("excedente_asegurado cambia a cero");
                    setFieldTouched("excedente_asegurado", false);
                    //quitar comentario setFieldValue("excedente_asegurado", '');
                    //console.log("acá 2");
                } else {
                    setFieldTouched("excedente_asegurado", false);
                    setFieldValue("excedente_asegurado", (parseFloat(values.total) - parseFloat(values.monto_aseguradora_paga)).toFixed(2));
                }
            } else {
                //console.log("excedente_asegurado cambia a cero");
                setFieldTouched("excedente_asegurado", false);
                //quitar comentario setFieldValue("excedente_asegurado", '');
                //console.log("acá 3");
            }
        } else {
            //console.log("no fue tocado monto_aseguradora_paga");
        }

    }, [
        values.monto_aseguradora_paga
    ]);

    useEffect(() => {
        if (touched.excedente_asegurado) {
            if (values.total && values.excedente_asegurado) {
                if (parseFloat(values.excedente_asegurado) > parseFloat(values.total)) {
                    //console.log("monto_aseguradora_paga cambia a cero");
                    setFieldTouched("monto_aseguradora_paga", false);
                    //quitar comentario setFieldValue("monto_aseguradora_paga", '');
                    //console.log("acá 4");
                } else {
                    setFieldTouched("monto_aseguradora_paga", false);
                    setFieldValue("monto_aseguradora_paga", (parseFloat(values.total) - parseFloat(values.excedente_asegurado)).toFixed(2));
                }
            } else {
                //console.log("monto_aseguradora_paga cambia a cero");
                setFieldTouched("monto_aseguradora_paga", false);
                //quitar comentario setFieldValue("monto_aseguradora_paga", '');
                //console.log("acá 5");
            }
        } else {
            //console.log("no fue tocado excedente_asegurado");
        }
    }, [
        values.excedente_asegurado
    ]);

    useEffect(() => {
        //console.log("aca 6", touched);
        if (touched.manejar_precios_netos) {
            setFieldTouched("monto_aseguradora_paga", false);
            setFieldTouched("excedente_asegurado", false);
            setFieldValue("monto_aseguradora_paga", '');
            setFieldValue("excedente_asegurado", '');
        }
    }, [
        values.manejar_precios_netos
    ]);





    useEffect(() => {

        if (!id) {
            if (values.order_locations) {
                let minimumLocations = 0;
                if (values.grua || values.corresponsalia || values.taxi) {
                    minimumLocations = 2;
                } else if (values.montacarga || values.auxilio_vial) { //OJO ACÁ
                    minimumLocations = 1;
                }
                minimumLocations = minimumLocations - values.order_locations.length;

                for (let i = 0; i < minimumLocations; i++) {
                    setFieldValue('order_locations', [
                        ...values.order_locations, {
                            id: "",
                            index: "",
                            cuadro_busqueda: "",
                            direccion: "",
                            referencia: "",
                            latitude: "",
                            longitude: "",
                            location_rate_id: "",
                            removable: false
                        }
                    ]);

                }
            }
        }

        if (!values.grua && touched.grua) {
            setFieldValue("grua_tarifa_kilometro", "");
            setFieldValue("grua_subtotal", "");
            //console.log("grua_subtotal a cero 1");
            setFieldValue("grua_banderazo", "");
            setFieldValue("grua_casetas", "");
        }
        if (!values.auxilio_vial && touched.auxilio_vial) {
            setFieldValue("aux_vial_tarifa_kilometro", "");
            setFieldValue("aux_vial_subtotal", "");
            setFieldValue("aux_vial_banderazo", "");
            setFieldValue("aux_vial_casetas", "");
        }
        if (touched.montacarga) {
            if (!values.montacarga) {
                setFieldValue("montacargas_costo_hora", "");
                setFieldValue("montacargas_horas", "");
                setFieldValue("montacargas_subtotal", "");
                setFieldValue("montacargas_traslado", "");
            } else {
                setFieldValue("vehiculos", []);
            }
        }

        if (!values.corresponsalia && touched.corresponsalia) {
            setFieldValue("corresponsalia_subtotal", "");
        }


        if (touched.taxi) {
            if (!values.taxi) {
                setFieldValue("taxi_subtotal", "");
            } else {
                setFieldValue("vehiculos", []);
            }
        }
    }, [
        values.grua,
        values.auxilio_vial,
        values.montacarga,
        values.corresponsalia,
        values.taxi
    ]);

    useEffect(() => {
        if (values?.servicio_muerto_monto_penalizacion === '' || values?.servicio_muerto_monto_penalizacion === null) {
            setFieldValue('withPenalizacion', false);
        } else {
            setFieldValue('withPenalizacion', true);
        }

    }, [values.servicio_muerto_monto_penalizacion]);


    useEffect(() => {


        if (values.vehiculos.some((vehicle) => vehicle.shielding_type_id !== '' && vehicle.shielding_type_id !== null)) {
            setFieldValue('someWithShielding', true);
        } else if (touched.someWithShielding) {
            setFieldValue('someWithShielding', false);
            setFieldValue("monto_blindaje", "");
        }
        let carga = false;
        let volumen = false;
        if (values.vehiculos.some((vehicle) => vehicle.carga_kg !== undefined && vehicle.carga_kg !== '' && vehicle.carga_kg !== null && parseFloat(vehicle.carga_kg) !== 0)) {
            setFieldValue('someWithCarga', true);
            carga = true;
        } else {
            setFieldValue('someWithCarga', false);
        }

        if (values.vehiculos.some((vehicle) => vehicle.registrar_volumen !== null && vehicle.registrar_volumen === true)) {
            setFieldValue('someWithVolumen', true);
            volumen = true;
        } else {
            setFieldValue('someWithVolumen', false);
        }

        if (touched.someWithCarga || touched.someWithVolumen) {
            if (!carga && !volumen) {
                setFieldValue("monto_carga_volumen", "");
            }
        }


    }, [values.vehiculos]);

    const fetchData = async () => {
        let montoServicio = null;
        try {
            if (values.client_type_id && values.crane_id && (values.client_type_id.client_type_code !== 'ASEGURADORA' || values.insurance_id)) {

                const locationRateIds = values.order_locations
                    .filter(location => location.location_rate_id?.id !== null && location.location_rate_id?.id !== undefined)
                    .map(location => location.location_rate_id.id);

                //Grúa
                if (values.grua) {
                    const response = await fetchRate(
                        locationRateIds,
                        values.client_type_id.value,
                        values.insurance_id.value,
                        values.crane_id.value,
                        'GRUA'
                    );

                    setTarifasGruaCalculadas(response?.data || []);

                    if (!id) {
                        if (!touched.grua_tarifa_kilometro) {
                            const maxRateKilometer = response?.data?.length
                                ? response.data.reduce((max, item) => Math.max(max, item.rate_kilometer), 0)
                                : "";
                            setFieldValue('grua_tarifa_kilometro', maxRateKilometer);
                            setFieldTouched('grua_tarifa_kilometro', false);
                        }

                        if (!touched.grua_banderazo) {
                            const maxFixedRate = response?.data?.length
                                ? response.data.reduce((max, item) => Math.max(max, item.fixed_rate), 0)
                                : "";
                            setFieldValue('grua_banderazo', maxFixedRate);
                            setFieldTouched('grua_banderazo', false);
                        }
                    }
                }

                if (values.auxilio_vial) {



                    const response = await fetchRate(
                        locationRateIds,
                        values.client_type_id.value,
                        values.insurance_id.value,
                        values.crane_id.value,
                        'AUX_VIAL'
                    );


                    setTarifasAuxVialCalculadas(response?.data || []);

                    if (!id) {
                        if (!touched.aux_vial_tarifa_kilometro) {
                            const maxRateKilometer = response?.data?.length
                                ? response.data.reduce((max, item) => Math.max(max, item.rate_kilometer), 0)
                                : "";
                            setFieldTouched('aux_vial_tarifa_kilometro', false);
                            setFieldValue("aux_vial_tarifa_kilometro", maxRateKilometer);

                        }

                        if (!touched.aux_vial_banderazo) {
                            const maxFixedRate = response?.data?.length
                                ? response.data.reduce((max, item) => Math.max(max, item.fixed_rate), 0)
                                : "";

                            setFieldTouched('aux_vial_banderazo', false);
                            setFieldValue('aux_vial_banderazo', maxFixedRate);

                        }
                    }

                }


                if (values.grua || values.auxilio_vial) {
                    const response = await fetchRate(
                        locationRateIds,
                        values.client_type_id.value,
                        values.insurance_id.value,
                        values.crane_id.value,
                        'CASETA'
                    );

                    setTarifasCasetas(response?.data || []);

                    if (!id) {
                        if (!touched.grua_casetas && values.grua === true) {
                            const maxFixedRate = response?.data?.length
                                ? response.data.reduce((max, item) => Math.max(max, item.fixed_rate), 0)
                                : "";
                            setFieldValue('grua_casetas', maxFixedRate);
                            setFieldTouched('grua_casetas', false);
                        }

                        if (!touched.aux_vial_caseta && values.auxilio_vial === true) {
                            const maxFixedRate = response?.data?.length
                                ? response.data.reduce((max, item) => Math.max(max, item.fixed_rate), 0)
                                : "";
                            setFieldValue('aux_vial_casetas', maxFixedRate);
                            setFieldTouched('aux_vial_casetas', false);
                        }
                    }
                }




                if (values.client_type_id.client_type_code === 'ASEGURADORA') {
                    if (values.insurance_id) {

                    } else {
                        montoServicio = {
                            monto_extra_descripcion: "SERVICIO",
                            monto_extra_monto: 100,
                            editable: true,
                            removable: false,
                            servicio_principal: true,
                            tarifa_kilometro: 0,
                        };
                    }
                } else {

                }
            }

            if (montoServicio) {
                setFieldValue('montosExtras', [montoServicio, ...values.montosExtras.slice(1)]);
            }


        } catch (error) {
            console.error('Error al obtener la tarifa:', error);
        }
    };


    useEffect(() => {
        if (values.edit_locations_finished) {
            fetchData();
        }

    }, [values.edit_locations_finished, values.client_type_id, values.insurance_id, values.crane_id, values.km]);

    useEffect(() => {
        if (touched.manejar_tarifas_establecidas) {
            setFieldValue("grua_subtotal", null);
            //console.log("grua_subtotal a cero 2");
            setFieldValue("grua_tarifa_kilometro", null);
            setFieldValue("grua_banderazo", null);
            setFieldValue("grua_casetas", null);
            setFieldValue("aux_vial_tarifa_kilometro", null);
            setFieldValue("aux_vial_banderazo", null);
            setFieldValue("aux_vial_subtotal", null);
            setFieldValue("aux_vial_casetas", null);
            setFieldValue("montacargas_costo_hora", null);
            setFieldValue("montacargas_horas", null);
            setFieldValue("montacargas_subtotal", null);
            setFieldValue("montacargas_traslado", null);
            setFieldValue("corresponsalia_subtotal", null);
            setFieldValue("taxi_subtotal", null);
        }

    }, [values.manejar_tarifas_establecidas]);





    useEffect(() => {
        if ((touched.grua_tarifa_kilometro || touched.km || touched.crane_id) && values.service_location_type_id.service_location_code === 'FORANEO') {
            if (values.grua_tarifa_kilometro && values.km) {
                setFieldValue("grua_subtotal", values.grua_tarifa_kilometro * values.km);
                //console.log("grua_subtotal a cero 3");
            } else {
                setFieldValue("grua_subtotal", '');
                //console.log("grua_subtotal a cero 4");
            }
        }
    }, [values.crane_id, values.grua_tarifa_kilometro, values.km]);

    useEffect(() => {
        if ((touched.aux_vial_tarifa_kilometro || touched.km || touched.crane_id) && values.service_location_type_id.service_location_code === 'FORANEO') {
            if (values.aux_vial_tarifa_kilometro && values.km) {
                setFieldValue("aux_vial_subtotal", values.aux_vial_tarifa_kilometro * values.km);
            } else {
                //console.log("se pasa a cero aux_vial_subtotal");
                setFieldValue("aux_vial_subtotal", '');
            }
        }
    }, [values.crane_id, values.aux_vial_tarifa_kilometro, values.km]);

    useEffect(() => {
        if (values.montacargas_costo_hora && values.montacargas_horas) {
            setFieldValue('montacargas_subtotal', values.montacargas_costo_hora * values.montacargas_horas);
        } else {
            setFieldValue('montacargas_subtotal', '');
        }

    }, [values.montacargas_costo_hora, values.montacargas_horas]);

    useEffect(() => {

        if (touched.orderStatuses && !values.orderStatuses?.ENVIADA?.has_passed_status && new Date(values.fecha_hora_cita) <= new Date()) {
            addNotification('warning', 'Fecha y hora de la cita', `La fecha y hora seleccionada para la cita es anterior a la fecha y hora actual.`, 'top-right');
        }



    }, [values.orderStatuses]);


    function addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition) {

        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: "top",
            animationIn: ["animate__animated", "animate__bounceInRight"],
            container: notificationPosition,
            animationOut: ["animate__animated", "animate__bounceOut"],
            dismiss: {
                duration: 5000,
                onScreen: true,
                pauseOnHover: true,
                waitForAnimation: true,
                showIcon: true
            },
        });
    }



    return (
        <>
            <ReactNotifications />

            <div className="row mb-3">
                <div className="col-xl-12">


                </div>
            </div>


            <Panel>
                <PanelHeader noButton={true}>Información General de la Carta del Servicio</PanelHeader>
                {values.folio ? <div className="alert alert-success alert-dismissible fade show rounded-0 mb-0">
                    <div className="d-flex justify-content-between">
                        <i className="fa-regular fa-folder-open fa-2x me-1"></i>
                        <div className="mb-0 ps-2">
                            <h5>Folio asignado: <b>{values.folio.toString().padStart(5, '0')}</b></h5>
                            Orden registrada el {' '}
                            {format(
                                new Date(values?.fecha_hora_reporte),
                                'dd/MMM/yyyy',
                                { locale: es }
                            )}
                            {' '} a las {' '}
                            {format(
                                new Date(values?.fecha_hora_reporte),
                                'hh:mm a',
                                { locale: es }
                            )} {' '} por {' '}
                            {(values?.base_id?.label.trim())}
                        </div>
                        <div className='ms-auto'>
                            <RouterLink to={'#'} onClick={() => handlePrintOrder()} className="btn btn-yellow float-end">Generar Reporte</RouterLink>
                        </div>

                    </div>
                    <button type="button" className="btn-close ms-3" data-bs-dismiss="alert"></button>
                </div> : ''}

                <PanelBody>
                    <OrderFieldsInformacionGeneral
                        opcionesEsCita={opcionesEsCita}
                        values={values}
                        bases={bases}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                    />

                </PanelBody>
            </Panel>


            <Panel>
                <PanelHeader noButton={true}>Información del Tipo de Servicio</PanelHeader>
                <PanelBody>

                    <OrderFieldsInformacionTipoServicio
                        values={values}
                        errors={errors}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                    />

                </PanelBody>
            </Panel>



            {values.grua || values.auxilio_vial || values.corresponsalia || values.montacarga ? (
                <Panel>
                    <PanelHeader noButton={true}>Datos de los bienes</PanelHeader>
                    <PanelBody>
                        <OrderFieldsDatosBienes
                            token={token}
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            touched={touched}
                            errors={errors}
                        />
                    </PanelBody>
                </Panel>

            ) : null}



            {(values.grua || values.auxilio_vial || values.corresponsalia || values.montacarga || values.taxi) ? (
                <Panel>
                    <PanelHeader noButton={true}>Datos de Contacto y Seguimiento</PanelHeader>
                    <PanelBody>
                        <OrderFieldsDatosContacto
                            tipoClientes={tipoClientes}
                            aseguradoras={aseguradoras}
                            paymentTypes={paymentTypes}
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            touched={touched}
                            errors={errors}
                            addNotification={addNotification}
                        />

                    </PanelBody>
                </Panel>

            ) : null}


            {(values.grua || values.auxilio_vial || values.montacarga || values.corresponsalia || values.taxi) ? (
                <Panel>
                    <PanelHeader noButton={true}>Ubicación de la atención</PanelHeader>
                    <PanelBody>
                        <OrderFieldsUbicacionAtencion
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            touched={touched}
                            errors={errors}
                            tiposLocalizacionServicio={tiposLocalizacionServicio}
                            modalidadesTarifa={modalidadesTarifa}
                        />

                    </PanelBody>
                </Panel>
            ) : null}

            {values.edit_locations_finished && (values.grua || values.auxilio_vial || values.montacarga || values.corresponsalia || values.taxi) ? (
                <Fragment>
                    <Panel>
                        <PanelHeader noButton={true}>Cálculo de montos</PanelHeader>
                        <PanelBody>

                            <OrderAmountsVariables
                                gruas={gruas}
                                vehiculosAsistencia={vehiculosAsistencia}
                                montacargas={montacargas}
                                operadores={operadores}
                                values={values}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                setFieldTouched={setFieldTouched}
                                errors={errors}
                            />

                            {(values.service_location_type_id.service_location_code === 'FORANEO' && values.manejar_tarifas_establecidas === false) && (
                                <Row className='mb-1'>
                                    <div className="note alert-primary mb-2">
                                        <div className="note-icon"><i className="fa-solid fa-calculator"></i></div>
                                        <div className="note-content">
                                            <h4><b>Cálculo de Tarifas</b></h4>
                                            <p>
                                                Tarifas informativas considerando: <br></br>
                                                Ubicaciones tarifarias seleccionadas en el mapa<br></br>
                                                Tipo de grúa: {values.crane_id ? values.crane_id.label : 'No se ha seleccionado una grúa'}<br></br>


                                                {!values.client_type_id || (values.client_type_id && values.client_type_id.client_type_code === 'ASEGURADORA') ? (
                                                    <>
                                                        Aseguradora: {values.insurance_id ? values.insurance_id.label : (!values.client_type_id ? 'No se ha seleccionado un tipo de cliente' : 'No se ha seleccionado una aseguradora')}<br></br>
                                                    </>
                                                ) : null}
                                            </p>
                                        </div>
                                    </div>
                                </Row>

                            )}


                            <Row className="invoice pb-0">
                                <div className="invoice-company">
                                    Cálculo del monto
                                </div>
                            </Row>

                            {values.montacarga === true && (
                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} md="12" >
                                        <Form.Label>Modalidad de tarifa</Form.Label>
                                        <Select
                                            name='manejar_tarifas_establecidas'
                                            options={modalidadesTarifa}
                                            className={errors.manejar_tarifas_establecidas ? 'is-invalid' : ''}
                                            placeholder="Selecciona la Modalidad de tarifa"
                                            isClearable={true}
                                            isSearchable={true}
                                            value={modalidadesTarifa.find(e => e.value === values.manejar_tarifas_establecidas)}
                                            onChange={(e) => {
                                                setFieldTouched("manejar_tarifas_establecidas", true);
                                                setFieldValue("manejar_tarifas_establecidas", e ? e.value : null);
                                            }}
                                            isDisabled={values.orderStatuses?.CIERRE?.has_passed_status || values.orderStatuses?.CANCELADO?.has_passed_status}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.manejar_tarifas_establecidas}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                            )}


                            <Row className='mb-1'>
                                <Form.Group as={Col} className="mt-3" >
                                    <Form.Check
                                        name="manejar_precios_netos"
                                        type="switch"
                                        label="Precios Netos"
                                        checked={values.manejar_precios_netos}
                                        onChange={(e) => {
                                            setFieldTouched("manejar_precios_netos", true);
                                            setFieldValue("manejar_precios_netos", e.target.checked);
                                        }}
                                        onBlur={handleBlur}
                                        disabled={values?.current_order_status_id?.order_status_code === 'CANCELADO' || values?.current_order_status_id?.order_status_code === 'CIERRE'}

                                    />
                                </Form.Group>
                            </Row>




                            <Row>
                                <OrderAmountsFieldArray
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    setFieldTouched={setFieldTouched}
                                    handleChange={handleChange}
                                    errors={errors}
                                    tarifasGruaCalculadas={tarifasGruaCalculadas}
                                    tarifasCasetas={tarifasCasetas}
                                    tarifasAuxVialCalculadas={tarifasAuxVialCalculadas}
                                    handleBlur={handleBlur}
                                    disabled={values?.current_order_status_id?.order_status_code === 'CANCELADO' || values?.current_order_status_id?.order_status_code === 'CIERRE'}
                                />
                            </Row>

                            <OrderFieldsResultAmount
                                values={values}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                errors={errors}
                            />

                        </PanelBody>
                    </Panel>



                    {id && (
                        <PaymentsAndInvoices
                            paymentTypes={paymentTypes}
                            tablePaymentsContadoRef={tablePaymentsContadoRef}
                            tablePaymentsCreditoRef={tablePaymentsCreditoRef}
                        />
                    )}

                    {(id && (values.grua || values.auxilio_vial || values.montacarga || values.corresponsalia || values.taxi)) && (
                        <Panel>
                            <PanelHeader noButton={true}>Evidencias</PanelHeader>
                            <PanelBody>
                                <div className="note alert-primary mb-2">
                                    <div className="note-icon"><i className="fa-regular fa-image"></i></div>
                                    <div className="note-content">
                                        <h4><b>Evidencias</b></h4>
                                        <p>
                                            Cargue o visualice las evidencias del servicio

                                            <button data-bs-toggle="modal" data-bs-target="#modalEvidences" className="btn btn-sm btn-primary ms-1" onClick={() => { setShowEvidences(!showEvidences) }}>Mostrar evidencias</button>
                                        </p>
                                    </div>
                                </div>

                                <div className="modal modal-message fade" id="modalEvidences">
                                    <div className="modal-dialog">
                                        {showEvidences && (
                                            <OrderFieldsEvidencias
                                                id={id}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                errors={errors}
                                                setShowEvidences={setShowEvidences}
                                            />

                                        )}
                                    </div>
                                </div>

                            </PanelBody>
                        </Panel>
                    )}

                    {(1 === 0 && values.current_order_status_id && (values.current_order_status_id.order_status_code === 'PAGADO' || values.current_order_status_id.order_status_code === 'EVALUADO')) && (
                        <Panel>
                            <PanelHeader noButton={true}>Evaluación del servicio</PanelHeader>

                            <OrderFieldsEvaluacion
                                opcionesEsCita={opcionesEsCita}
                                paymentTypes={paymentTypes}
                                values={values}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                errors={errors}
                            />


                        </Panel>

                    )}

                    {/*
                    <Panel>
                        <PanelHeader noButton={true}>Condiciones del vehículo</PanelHeader>
                        <PanelBody>
                            <div className="note alert-primary mb-2">
                                <div className="note-icon"><i className="fa-solid fa-circle-exclamation"></i></div>
                                <div className="note-content">
                                    <h4><b>Información importante</b></h4>
                                    <p>
                                        El vehículo se recibe para su traslado con los rayones/golpes/daños que se señalan.
                                    </p>
                                </div>
                            </div>


                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" >
                                    <Form.Label>Fotografías</Form.Label>

                                    <Dropzone
                                        onDrop={handleDrop}
                                        accept="image/*"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="dropzone">
                                                <input {...getInputProps()} />
                                                <p>Arrastra y suelta las fotografías del vehículo aquí, o dé click para seleccionar las fotos.</p>
                                            </div>
                                        )}
                                    </Dropzone>


                                </Form.Group>
                            </Row>

                        </PanelBody>
                    </Panel>

                     */}

                    <Row className="mb-3 ">
                        <Col md="12 " >


                            {(id && values?.current_order_status_id?.order_status_code !== 'CANCELADO' && values?.current_order_status_id?.order_status_code !== 'CIERRE') && (
                                <div className='float-start'>
                                    {values?.current_order_status_id?.order_status_code !== 'CANCELADO_PAGO_PENDIENTE'
                                        ?
                                        <RouterLink to={'#'} onClick={() => handleCancelOrder(setFieldValue, values.insurance_id, values.fecha_hora_contacto, values.fecha_hora_reporte, values.totalPendingToPay)} className="btn btn-danger">
                                            Cancelar Orden
                                        </RouterLink>
                                        :
                                        <RouterLink to={'#'} onClick={() => handleEndCancelOrder(setFieldValue, values.insurance_id, values.fecha_hora_contacto, values.fecha_hora_reporte, values.totalPendingToPay)} className="btn btn-danger">
                                            Finalizar cancelación
                                        </RouterLink>}

                                </div>
                            )}

                            <div className='float-end'>
                                {currentOrderStatus?.order_status_code === 'CANCELADO_PAGO_PENDIENTE' || (!values.orderStatuses?.CIERRE?.has_passed_status && !values.orderStatuses?.CANCELADO?.has_passed_status) ? (
                                    <>
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting}
                                            data-tooltip-id='tooltip_submit_button'
                                            style={{ pointerEvents: 'auto' }}
                                            onClick={async (event) => {
                                                event.preventDefault();
                                                //console.log("cliccccc");
                                                if (!isSubmitting) {
                                                    //console.log("cliccccccccccssss");

                                                    setSubmitting(true);
                                                    validateForm().then(() => {
                                                        if (errors && Object.keys(errors).length !== 0) {
                                                            addNotification('warning', 'Errores en el formulario', `Se han encontrado errores en el formulario. Favor de corregirlos antes de intentar guardar nuevamente.`, 'top-right');
                                                            console.log("errors al enviar orden", errors);
                                                            console.log("valores actuales para envío", values);
                                                            const errorElement = document.querySelector('.invalid-feedback:not(:empty)');
                                                            if (errorElement) {
                                                                const navbarHeight = document.getElementById('header').offsetHeight || 0;
                                                                const errorElementTop = errorElement.parentNode.getBoundingClientRect().top + window.scrollY;

                                                                window.scrollTo({
                                                                    top: errorElementTop - navbarHeight,
                                                                    behavior: 'smooth',
                                                                });
                                                            }
                                                            setSubmitting(false);
                                                        } else {
                                                            //console.log("No hay errores de validación");
                                                            handleSubmit();
                                                        }

                                                    })
                                                } else {
                                                    addNotification('warning', 'Solicitud de Guardado enviada', `Su solicitud de guardado de cambios ha sido enviada. Por favor, espere mientras procesamos su solicitud.`, 'top-right');
                                                }

                                            }}>Guardar cambios</Button>{' '}
                                        <Tooltip id='tooltip_submit_button' place="right" style={{ zIndex: 9999 }} >
                                            Su solicitud de guardado de cambios ha sido enviada. Por favor, espere mientras procesamos su solicitud.
                                        </Tooltip>

                                    </>
                                ) : null}

                                <RouterLink to={'/orders'} className="btn btn-info">Regresar</RouterLink>
                            </div>

                        </Col>
                    </Row>






                </Fragment>
            ) : null}






        </>
    );
});

export default OrderFields;